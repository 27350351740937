








import Vue from 'vue'
import { BaseCard, BaseCardBody } from '@/components'
import { Processes } from './components/Processes'

export default Vue.extend({
    name: 'AdminManageDataAddresses',

    components: { Processes, BaseCard, BaseCardBody },
})
