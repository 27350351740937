var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{staticClass:"mt-2 mb-2",attrs:{"type":"submit","variant":"success"},on:{"click":function($event){_vm.showProcessDetail = true
            _vm.setProcess(_vm.emptyProcess)}}},[_vm._v("+ Add process")]),_c('b-table',{attrs:{"items":_vm.processes,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('p',[_vm._v(_vm._s(data.item.name))])]}},{key:"cell(action)",fn:function(row){return [_c('BaseBtn',{attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){_vm.setProcess(row.item)
                    _vm.showProcessDetail = true}}},[_vm._v("Edit process ")]),_c('BaseBtn',{staticClass:"ml-2",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){return _vm.copyProcess(row.item.id)}}},[_vm._v("Clone process ")]),_c('BaseBtnDelete',{staticClass:"ml-2",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeProcess(row.item.id)}}},[_vm._v("Delete process ")]),_c('BaseBtn',{staticClass:"ml-2",attrs:{"variant":"outline-success","size":"sm"},on:{"click":row.toggleDetails}},[_vm._v("Show process details ")])]}},{key:"row-details",fn:function(row){return [_c('b-table',{attrs:{"items":row.item.stages,"fields":[
                    'stage',
                    'name',
                    'daysToNextStage',
                    'elapsedTime',
                    'details',
                    'action' ]}})]}}])}),_c('b-modal',{attrs:{"title":"Create new recruitment process","size":"lg","hide-footer":"hide-footer"},model:{value:(_vm.showProcessDetail),callback:function ($$v) {_vm.showProcessDetail=$$v},expression:"showProcessDetail"}},[_c('ProcessDetail',{on:{"addOrUpdate":_vm.addOrUpdateProcess,"closeModal":_vm.closeModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }