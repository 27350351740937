











































































import Vue from 'vue'
import { STORE_MODULE, MUTATION_DASHBOARD_PROCESS } from '@/enums'
import { createNamespacedHelpers } from 'vuex'
import { IProcess, IProcessStage } from '@/types'
const { mapMutations: mapMutations } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_PROCESS)

export default Vue.extend({
    name: 'stage',
    data() {
        return {
            stage: {} as IProcessStage,
        }
    },

    methods: {
        ...mapMutations({
            addNewStage: MUTATION_DASHBOARD_PROCESS.ADD_STAGE,
        }),

        addStage() {
            this.addNewStage(this.stage)
            this.stage = {} as IProcessStage
        },
    },
})
