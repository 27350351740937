













































import Vue from 'vue'
import { STORE_MODULE, MUTATION_DASHBOARD_PROCESS } from '@/enums'
import { createNamespacedHelpers } from 'vuex'
import { IProcess, IProcessStage } from '@/types'
import { BaseBtn } from '@/components'
const { mapMutations: mapMutations } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_PROCESS)

export default Vue.extend({
    name: 'Stages',
    props: {
        stages: {
            type: Array as () => IProcessStage[],
            required: true,
        },
    },

    components: { BaseBtn },

    computed: {
        total(): number {
            return this.stages.reduce((acc, curr) => acc + curr.daysToNextStage, 0)
        },
    },

    data() {
        return {
            fields: [
                { key: 'stage', label: 'Stage', thStyle: { width: '50px' } },
                { key: 'name', label: 'Name' },
                {
                    key: 'daysToNextStage',
                    label: 'Days To Next Stage',
                    thStyle: { width: '150px' },
                },
                { key: 'elapsedTime', label: 'Elapsed Time', thStyle: { width: '200px' } },
                { key: 'details', label: 'Details' },
                'Action',
            ],
        }
    },

    methods: {
        ...mapMutations({
            deleteStage: MUTATION_DASHBOARD_PROCESS.DELETE_STAGE,
        }),
        remove(index: number) {
            this.deleteStage(this.stages[index])
        },
    },
})
