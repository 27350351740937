









































































import Vue from 'vue'
import { IStateDashboardProcess, IStateAuth, IProcess, IProcessStage } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { BaseBtn, BaseBtnDelete } from '@/components'
import { ProcessDetail } from './components/ProcessDetail'
import { ACTION_DASHBOARD_PROCESS, STORE_MODULE, MUTATION_DASHBOARD_PROCESS } from '@/enums'

const { mapState: mapStateAuth } = createNamespacedHelpers(STORE_MODULE.AUTH)

const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
    STORE_MODULE.DASHBOARD_PROCESS
)

export default Vue.extend({
    name: 'ManageAddresses',

    components: { BaseBtn, ProcessDetail, BaseBtnDelete },

    computed: {
        ...(mapStateAuth(['userAccount']) as MapStateToComputed<Required<IStateAuth>>),
        ...(mapState(['processes']) as MapStateToComputed<IStateDashboardProcess>),
    },

    created() {
        this.fetchAndSetProcesses(this.userAccount?.userCompanyId)
    },

    data() {
        return {
            fields: [{ key: 'name' }, { key: 'action' }],
            showProcessDetail: false,
            emptyProcess: {
                stages: [],
            },
        }
    },

    methods: {
        ...mapActions({
            fetchAndSetProcesses: ACTION_DASHBOARD_PROCESS.FETCH_AND_SET_PROCESSES,
            addProcess: ACTION_DASHBOARD_PROCESS.ADD_PROCESS,
            updateProcess: ACTION_DASHBOARD_PROCESS.UPDATE_PROCESS,
            cloneProcess: ACTION_DASHBOARD_PROCESS.CLONE_PROCESS,
            deleteProcess: ACTION_DASHBOARD_PROCESS.DELETE_PROCESS,
        }),
        ...mapMutations({
            setProcess: MUTATION_DASHBOARD_PROCESS.SET_PROCESS,
        }),

        addOrUpdateProcess(process: IProcess) {
            if (this.userAccount) process.companyId = this.userAccount?.userCompanyId
            if (process.id) this.updateProcess(process)
            else this.addProcess(process)
        },

        removeProcess(id: number) {
            this.deleteProcess(id)
        },

        copyProcess(id: number) {
            this.cloneProcess(id)
        },

        closeModal() {
            this.showProcessDetail = false
            this.fetchAndSetProcesses(this.userAccount?.userCompanyId)
        },
    },
})
