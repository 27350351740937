

















import Vue from 'vue'
import { MUTATION_DASHBOARD_PROCESS, STORE_MODULE } from '@/enums'
import { createNamespacedHelpers } from 'vuex'
import { Stages, StageDetail } from './components'
import { IProcess, IProcessStage, IStateDashboardProcess } from '@/types'
const { mapState } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_PROCESS)

export default Vue.extend({
    name: 'ProcessDetail',
    components: { Stages, StageDetail },

    computed: {
        ...(mapState(['process']) as MapStateToComputed<IStateDashboardProcess>),
    },

    methods: {
        addOrUpdate() {
            this.$emit('addOrUpdate', this.process)
            this.$emit('closeModal', this.process)
        },
    },

    data() {
        return {
            fields: [
                { key: 'stage', label: 'Stage', thStyle: { width: '50px' } },
                { key: 'name', label: 'Name' },
                {
                    key: 'daysToNextStage',
                    label: 'Days To Next Stage',
                    thStyle: { width: '150px' },
                },
                { key: 'elapsedTime', label: 'Elapsed Time', thStyle: { width: '200px' } },
                { key: 'details', label: 'Details' },
                'Action',
            ],
        }
    },
})
